import FuseSidePanel from "@fuse/components/FuseSidePanel/FuseSidePanel";
import FuseShortcuts from "@fuse/components/FuseShortcuts/FuseShortcuts";

function LeftSideLayout3() {
  return (
    <>
      <FuseSidePanel>
        <FuseShortcuts className="py-16 px-8" variant="vertical" />
      </FuseSidePanel>
    </>
  );
}

export default LeftSideLayout3;
