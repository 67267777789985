import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Button,
  CircularProgress,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import Formsy from "formsy-react";
import { useTranslation } from "react-i18next";
import { showMessage } from "app/store/actions";
import { useDispatch } from "react-redux";
import { TextFieldFormsy } from "@fuse";
import { handleException } from "@exiry/helpers/error.helpers";

const gQl = gql`
  mutation CreateDenounciation($input: CreateDenounciationInput!) {
    createDenounciation(input: $input) {
      recommendationDate
      ownerName
      address
      city
      postalCode
      projectId
      projectName
      projectAddress
      lotNumber
      companyName
      managerName
      managerEmail
      amount
      signatureDate
      signer
      function
      signedFor
      status
      apiUrl
      accessToken
    }
  }
`;
const gql_query = gql`
  query Project($projectId: ID!) {
    project(id: $projectId) {
      city
      poClient
    }
  }
`;
function DenunciationForm({ projectId }) {
  const { t } = useTranslation("denounciation");
  const filledFormValues = useRef({});
  const {
    data: gqlData,
    error,
    loading: projectLoading
  } = useQuery(gql_query, {
    variables: { projectId },
    fetchPolicy: "no-cache"
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [mutate, { loading }] = useMutation(gQl);
  const [isAutoSaving, setIsAutoSaving] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const [delay] = useState(3000);
  const formRef = useRef(null);

  const projectData = gqlData?.project;
  useEffect(() => {
    if (projectData) {
      setFormValues((prevValues) => ({
        ...prevValues,
        projectName: projectData.poClient,
        projectAddress: projectData.city
      }));
    }
  }, [projectData]);
  function enableButton() {
    setIsFormValid(true);
  }
  function disableButton() {
    setIsFormValid(false);
  }

  useEffect(() => {
    const autoSave = async () => {
      try {
        const input = {
          ...filledFormValues.current,
          status: t("not_completed")
        };
        await mutate({
          variables: {
            input: input
          }
        });
      } catch (error) {
        handleException(error);
      }
      setIsAutoSaving(true);
    };

    let timeout;
    if (isAutoSaving && Object.keys(formValues).length > 0) {
      timeout = setTimeout(() => {
        autoSave();
      }, delay);
    }

    return () => clearTimeout(timeout);
  }, [formValues, isAutoSaving, delay]);

  const handleSubmit = async () => {
    let variant = "success";
    let message = "";
    console.log({ projectId });
    try {
      const input = {
        ...filledFormValues.current,
        projectId,
        status: t("completed")
      };
      const { data } = await mutate({
        variables: {
          input: input
        }
      });
      filledFormValues.current = {};
      setIsSubmitted(true);
      message = t("success.created");
    } catch (error) {
      variant = "error";
      handleException(error);
      message = t("error.created");
    } finally {
      dispatch(showMessage({ message, variant }));
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  useEffect(() => {
    filledFormValues.current = { ...filledFormValues.current, ...formValues };
  }, [formValues]);
  return (
    <div className="max-w-[100%] md:mx-auto ">
      <div className="flex gap-68 my-5 justify-center items-end">
        <div>
          <img src="/assets/images/pdf-logos/beton.png" alt="" />
        </div>

        <div>
          <img src="/assets/images/pdf-logos/royal.png" alt="" />
        </div>
        <div>
          <img src="/assets/images/pdf-logos/EBC.png" alt="" />
        </div>
      </div>
      <Typography variant="h5" align="center" className="my-44 font-bold">
        {t("denounciation_form")}
      </Typography>

      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="flex flex-col justify-center w-full"
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" className="justify-around">
                <strong className="underline">{t("recommended")}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6} alignItems="center" className="flex">
              <span>{t("on")}</span>
              <TextFieldFormsy
                type="date"
                margin="dense"
                className="w-[50%] ml-2 mr-2"
                name="recommendationDate"
                variant="outlined"
                fullWidth
                required
                onChange={handleInputChange}
                value={formValues.recommendationDate || ""}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/2" variant="subtitle1">
              {t("owner_name")}
            </Typography>
            <TextField
              margin="dense"
              className="w-1/2"
              name="ownerName"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
              value={formValues.ownerName || ""}
            />
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/4" variant="subtitle1">
              {t("address")}
            </Typography>
            <TextField
              name="address"
              margin="dense"
              className="w-3/4"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
              value={formValues.address || ""}
            />
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/4" variant="subtitle1">
              {t("city")}
            </Typography>
            <TextField
              name="city"
              margin="dense"
              className="w-3/4"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
              value={formValues.city || ""}
            />
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/4" variant="subtitle1">
              {t("postal_code")}
            </Typography>
            <TextFieldFormsy
              name="postalCode"
              margin="dense"
              className="w-3/4"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
              value={formValues.postalCode || ""}
            />
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/4">{t("object")}</Typography>
            <strong className="w-3/4">{t("project_denounciation")}</strong>
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/4" variant="subtitle1">
              {t("project_name")}
            </Typography>
            <TextFieldFormsy
              name="projectName"
              margin="dense"
              className="w-3/4"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
              value={formValues.projectName || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/4" variant="subtitle1">
              {t("project_address")}
            </Typography>
            <TextFieldFormsy
              name="projectAddress"
              margin="dense"
              className="w-3/4"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              value={formValues.projectAddress || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/4" variant="subtitle1">
              {t("lot_number")}
            </Typography>
            <TextFieldFormsy
              name="lotNumber"
              margin="dense"
              className="w-3/4"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
              value={formValues.lotNumber || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <p variant="body1">Madame, Monsieur,</p>
            <div className="flex flex-wrap gap-y-2 items-center">
              <span variant="body1" className="flex flex-wrap" alignItems="top">
                Il nous fait plaisir de vous informer que nous sommes
                fournisseur de béton et/ou matériaux et qu’un contrat nous a été
                accordé par la compagnie
              </span>
              <TextFieldFormsy
                margin="dense"
                name="companyName"
                variant="outlined"
                className="w-[25%] ml-2 mr-2"
                required
                onChange={handleInputChange}
                value={formValues.companyName || ""}
              />
              <span>
                pour la fourniture de béton préparé et/ou matériaux, le
                responsable de ce projet pour votre entreprise est
              </span>
              <TextFieldFormsy
                margin="dense"
                className="w-[20%] ml-2 mr-2"
                name="managerName"
                label="Prénom,nom"
                variant="outlined"
                required
                onChange={handleInputChange}
                value={formValues.managerName || ""}
              />
              /
              <TextFieldFormsy
                margin="dense"
                name="managerEmail"
                className="w-[20%] ml-2 mr-2"
                label="Courriel"
                variant="outlined"
                required
                onChange={handleInputChange}
                value={formValues.managerEmail || ""}
                placeholder="test@test.com"
                validations={{
                  matchRegexp:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                }}
                validationErrors={{
                  matchRegexp: t("translation:error.form.email")
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="flex flex-wrap gap-y-2 items-center">
              <span variant="body1" className="flex flex-wrap" alignItems="top">
                {t("approximate_amount")}
              </span>
              <TextFieldFormsy
                margin="dense"
                className="w-[25%] ml-2 mr-2"
                name="amount"
                variant="outlined"
                fullWidth
                required
                onChange={handleInputChange}
                value={formValues.amount || ""}
              />
              <span variant="body1" className="flex flex-wrap" alignItems="top">
                taxes en sus.
              </span>
            </div>

            <p variant="body1">
              Cet avis vous est donné en conformité avec les dispositions de
              l’article 2724 et suivants du Code Civil du Québec aux fins de
              conservation de notre droit à une hypothèque. Cependant, cet avis
              ne doit en aucune manière être interprété comme portant atteinte à
              la solvabilité ou à l’intégrité des personnes ou compagnie
              mentionnée mais plutôt comme le respect des exigences du Code
              Civil du Québec.
            </p>
          </Grid>
          <Grid item xs={12}>
            <div className="flex flex-wrap gap-y-2 items-center">
              <span variant="body1" className="flex flex-wrap" alignItems="top">
                {t("signature")}
              </span>
              <TextFieldFormsy
                type="date"
                margin="dense"
                className="w-[25%] ml-2 mr-2"
                name="signatureDate"
                variant="outlined"
                required
                onChange={handleInputChange}
                value={formValues.signatureDate || ""}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} className="flex items-center">
                <Typography className="w-1/5" variant="subtitle1">
                  {t("by")}
                </Typography>
                <TextFieldFormsy
                  margin="dense"
                  className="w-4/5"
                  name="signer"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={handleInputChange}
                  value={formValues.signer || ""}
                />
              </Grid>
              <Grid item xs={6} className="flex items-center">
                <Typography className="w-1/5" variant="subtitle1">
                  {t("function")}
                </Typography>
                <TextFieldFormsy
                  margin="dense"
                  className="w-4/5"
                  name="function"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={handleInputChange}
                  value={formValues.function || ""}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={2}
                className="flex"
                style={{ alignItems: "center" }}
              >
                <Typography className="w-1/5" variant="subtitle1">
                  {t("for")}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <RadioGroup
                  required
                  name="signedFor"
                  value={formValues.signedFor || ""}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="BetonChevalier"
                    label="Béton Chevalier inc"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="BetonRoyal"
                    label="Béton Royal inc"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="BetonJFK"
                    label="Béton JFK inc"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="EntrepriseB"
                    label="Entreprise B. Chevalier inc"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>

          <p>
            c. c. Notre client<br></br> c. c. Au particulier si nécessaire
          </p>
          <hr className="border-t-black w-[90%] mt-2 " align="center"></hr>
          <p align="center" className="mt-2">
            Béton Chevalier - 418-848-1966 | Béton Royal - 819-820-7777 | Béton
            JFK - 450-638-7625 | Entreprise B. Chevalier - 418-848-1966
            Télécopieur - 418-848-4431 | Télécopieur - 514-400-8911 |
            Télécopieur - 450-638-2733 | Télécopieur - 418-848-4431
          </p>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-224 mx-auto mt-16"
          id="create_denounciation_button"
          disabled={!isFormValid}
        >
          {t("translation:save")}
          {loading && <CircularProgress size={24} />}
        </Button>
      </Formsy>
    </div>
  );
}

export default DenunciationForm;
