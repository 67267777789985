import "typeface-muli";
//import "./react-chartjs-2-defaults";
import "./styles/index.css";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import App from "./app/App";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://396ab3a367ae4520a6fa75bcaef7db34@sentry.dev.globetechnologie.net/4",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0
// });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
