const main = [
  {
    id: "applications",
    title: "Portail Béton Chevalier",
    translate: "portal",
    type: "group",
    icon: "apps",
    auth: "login",
    children: [
      {
        id: "dashboard",
        translate: "dashboard",
        type: "item",
        icon: "dashboard",
        url: "/app/dashboard",
        auth: "login"
      },
      {
        id: "project",
        translate: "projects",
        type: "item",
        icon: "ballot",
        url: "/app/projects/list",
        auth: "login"
      },
      {
        id: "agreement",
        translate: "agreements",
        type: "item",
        icon: "folder",
        url: "/app/agreements/list",
        auth: "login"
      },
      {
        id: "invoice",
        translate: "invoices",
        type: "item",
        icon: "receipt",
        url: "/app/invoices/list",
        auth: "login"
      },
      {
        id: "ticket",
        translate: "tickets",
        type: "item",
        icon: "confirmation_number",
        url: "/app/tickets/list",
        auth: "login"
      }
    ]
  }
];

const user = [
  {
    id: "account",
    translate: "account",
    type: "group",
    auth: "login",
    children: [
      {
        id: "my_profile",
        translate: "my_profile",
        type: "item",
        auth: "login",
        icon: "account_circle",
        url: "/users/profile"
      },
      {
        id: "factory_info",
        translate: "factory_info",
        type: "item",
        auth: "login",
        icon: "info",
        url: "/app/customer"
      }
      // {
      //   id: "open_a_new_account",
      //   translate: "open_a_new_account",
      //   type: "item",
      //   auth: "login",
      //   icon: "info",
      //   url: "/customer_account"
      // }
    ]
  }
];

const help = [
  {
    id: "phone-assistance",
    translate: "phone_assistance",
    type: "item",
    auth: "login",
    icon: "phone",
    url: "/app/phone-assistance"
  }
];

export default main;

export { main, user, help };
