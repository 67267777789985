import React from "react";

import fr_FR from "./i18n/fr_FR.json";
import en_CA from "./i18n/en_CA.json";
import i18next from "i18next";

i18next.addResourceBundle("fr-CA", "customer", fr_FR);
i18next.addResourceBundle("en-CA", "customer", en_CA);

export const CustomerPageConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: "login",
  routes: [
    {
      path: "/app/customer",
      component: React.lazy(() => import("./CustomerPage"))
    }
  ]
};
