// ProprietairesEntreprise.js
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DatePickerFormsy from "@exiry/components/formsy/DatePickerFormsy";

function GeneralConditions() {
  const { t } = useTranslation("customer");

  return (
    <Grid container spacing={2}>
      <div className="mt-20">
        <h2 className="font-bold">{t("general_conditions")}</h2>
        <ol>
          <li>
            <strong className="underline">1 DÉFINITIONS</strong>
          </li>
          <ol>
            <li>
              1.1 L’expression «Client» comprend tout représentant dûment
              autorisé à l’emploi du Client, ses employés ou ses mandataires
              sous-traitants ou tout autre intervenant ayant autorité sur les
              chantiers, au nom du «Client». Dans tous les cas, aux fins des
              présentes de même qu’aux fins de tout Contrat à être conclu dans
              le cadre de la présente Entente, le Client représente et garantie
              qu’il est ou sera dûment mandaté par le maître d’œuvre pour
              l’exécution des obligations contractées et à être contractée dans
              le cadre des présente Conditions et des Contrats à intervenir;
            </li>
            <li>
              1.2 L’expression «Fournisseur» comprend tout représentant dûment
              autorisé du fournisseur, ses employés ou ses mandataires
              (sous-traitants) ou tout autre intervenant ayant l’autorité légale
              pour lier le fournisseur.
            </li>
          </ol>
          <li>
            <strong className="underline">2 OBJET</strong>
          </li>
          <ol>
            <li>
              2.1 Chaque contrat décrira entre autres la date et le lieu de
              livraison ainsi que les mélanges, spécifications, prix et quantité
              de béton exigés par le Client;
            </li>
            <li>
              2.2 Tout Contrat accepté par le Client lie les parties et est
              gouverné par les termes et conditions, une commande placée
              constitue une acceptation du contrat par le Client.
            </li>
          </ol>
          <li>
            <strong className="underline">3 DURÉE</strong>
          </li>
          <ol>
            <li>
              3.1 La durée et la cessation des conditions générales sont régies
              par le contrat.
            </li>
          </ol>
          <li>
            <strong className="underline">4 GARANTIES</strong>
          </li>
          <ol>
            <li>
              4.1 L’ensemble du béton produit et livré parvient d’une usine
              certifiée selon le protocole BNQ 2621-905 en vigueur;
            </li>
            <li>
              4.2 Le Fournisseur garantit le béton selon les prescriptions de la
              certification BNQ 2621-905 en vigueur. Le Fournisseur n’est pas
              responsable, en aucun cas, de la mise en place du produit de
              béton;
            </li>
            <li>
              4.3 Le Fournisseur n’est pas responsable du choix de la
              spécification du béton commandé par le Client, nonobstant les
              exigences de la revue de contrat de la certification. Sa
              responsabilité se limite à la conformité du béton fourni selon les
              spécifications ou descriptions contenues dans le contrat.
            </li>
          </ol>
          <li>
            <strong className="underline">
              5 QUANTITÉ, DÉLAIS DE LIVRAISON ET ANNULATION
            </strong>
          </li>
          <ol>
            <li>
              5.1 Le nombre de mètres cubes indiqués sur les billets de
              livraison est considéré comme preuve concluante de la quantité
              livrée. Toute divergence de quantité doit être signifiée au moment
              de la livraison pour que le Client puisse conserver son droit de
              contestation. Le Client est responsable, dans l’évaluation du
              volume de béton requis au chantier, d’assumer les ajustements
              nécessaires aux quantités commandées en considérant entre autres
              les différents facteurs suivants : la prise d’échantillon par le
              surveillant, la perte d’air entraîné au pompage, lors de l’attente
              au chantier ou à la mise en place (vibration), la perte pour
              l’amorçage de la pompe et durant l’opération de celle-ci, la
              déformation des coffrages sous la pression du béton, l’inégalité
              ou l’affaissement du sol, les pertes liées aux opérations de
              chantier et les pertes inhérentes causées par la propriété
              collante du béton lors de la livraison par bétonnière.
            </li>
            <li>
              5.2 Le Fournisseur s’engage à livrer au Client le béton commandé
              24 heures à l’avance, ainsi qu’au débit indiqué dans l’avis
              mentionné au paragraphe 5.1. Le Client s’engage à respecter le
              débit de coulée qu’il a commandé au Fournisseur, le non-respect
              est soumis aux frais définis au contrat;
            </li>
            <li>
              5.3 En cas d’annulation ou de modification de la part du Client,
              celui-ci doit aviser le Fournisseur, soit avant 17h la veille de
              la coulée lorsque celle-ci a lieu avant 10h ou 4h avant celle-ci
              lorsqu’elle a lieu après 10h. Dans le cas contraire, des frais
              pourront être facturés.
            </li>
          </ol>
          <li>
            <strong className="underline">
              6 RESPONSABILITÉ EN CAS DE RETARD
            </strong>
          </li>
          <ol>
            <li>
              6.1 La responsabilité du Fournisseur pour tout retard dans la
              livraison du béton commandé par le Client ne pourra pas être
              supérieur à la valeur de la marchandise livrée en retard;
            </li>
            <li>
              6.2 Le Client sera tenu de payer le coût du béton désigné au
              Contrat si le béton est refusé au chantier pour une raison autre
              que la non-conformité de ce dernier aux spécifications du Contrat
              ou si le béton est devenu inutilisable par suite de tout retard
              dans ladite livraison causé par le Client;
            </li>
            <li>
              6.3 Le Fournisseur s’engage en cas de bris mécaniques et de
              difficultés de production à mettre en œuvre les moyens nécessaires
              pour respecter la livraison. Toutefois, le Fournisseur ne sera pas
              responsable de tout retard causé par cas fortuit ou forme majeure,
              grève, lock-out, les conditions routières (accident, trafic,
              etc.).
            </li>
          </ol>
          <li>
            <strong className="underline">7 CONFORMITÉ DU BÉTON</strong>
          </li>
          <ol>
            <li>
              7.1 Pour le béton frais, seul les essais effectués sur le béton
              prélevé à la sortie de la bétonnière sont opposables au
              Fournisseur;
            </li>
            <li>
              7.2 Si le Client et le Maître d’œuvre n’ont pas effectué les
              essais afin de vérifier la qualité du béton, ils sont réputés
              avoir accepté que le béton réponde aux spécifications du Contrat
              et les contrôles internes du Fournisseur seront réputé en faire
              foi;
            </li>
            <li>
              7.3 Si le Client conteste la qualité du béton, le Fournisseur aura
              le droit d’effectuer ou de faire effectuer ses propres essais sur
              le béton frais et durcis dont la qualité sera contestée. Il est
              entendu entre les parties qu’avant une reprise des travaux une
              entente entre les parties doit être convenue.
            </li>
          </ol>
          <li>
            <strong className="underline">
              8 AJOUTS D’EAU OU DE TOUT AUTRE ADDITIF
            </strong>
          </li>
          <ol>
            <li>
              8.1 L’ajustement au chantier pour rencontrer les spécifications
              est la responsabilité du Fournisseur, le tout selon les exigences
              de la certification BNQ 2621-905 en vigueur;
            </li>
            <li>
              8.2 Pour la mise en place du béton, il est strictement interdit
              d’ajouter de l’eau dans le matériau au-delà des exigences de la
              norme (CSA A23.1 et BNQ 2621- 905 :2012), ce qui réduit la
              performance du béton et qui occasionne de la fissuration ainsi que
              sa dégradation prématurée. Il est recommandé d’utiliser un
              adjuvant (superplastifiant) pour augmenter la maniabilité du béton
              et faciliter la mise en place du béton;
            </li>
            <li>
              8.3 Le Fournisseur ne sera pas responsable de la qualité du béton
              si le Client a ajouté ou a accepté que l’on ajoute de l’eau;
            </li>
            <li>
              8.4 Tout autre additif ajouté par le Client et ne provenant pas du
              Fournisseur, dégage le Fournisseur de toute responsabilité reliée
              à la qualité du béton.
            </li>
          </ol>
          <li>
            <strong className="underline">9 CONDITIONS CLIMATIQUES</strong>
          </li>
          <ol>
            <li>
              9.1 Le Fournisseur se réserve le droit de différer la livraison
              s’il juge que les conditions climatiques rendent celle-ci
              dangereuses pour le public, le personnel et l’équipement;
            </li>
            <li>
              9.2 Les normes applicables et certaines spécifications imposent
              une livraison du béton à une température spécifique, ce qui
              justifie une charge additionnelle pour la manutention automnale,
              hivernale et estivale. Le Fournisseur se réserve le droit de
              refuser la livraison dans des conditions atmosphériques extrêmes
              soit plus froid que -18˚ C et plus chaud que 35˚ C.
            </li>
          </ol>
          <li>
            <strong className="underline">10 CONDITIONS DE CHANTIER</strong>
          </li>
          <ol>
            <li>
              10.1 Le Client avisera le Fournisseur ou ses représentants du
              chemin que ce dernier doit suivre de l’entrée du chantier au lieu
              de la coulée et identifiera ce chemin adéquatement;
            </li>
            <li>
              10.2 Les camions du Fournisseur ne s’engagent sur les trottoirs,
              entrées et propriétés privées, que sur les instructions du Client
              et aux risques de celui-ci. Le Client tiendra indemne le
              Fournisseur de tous les dommages pouvant résulter de ses actes;
            </li>
            <li>
              10.3 Le Client doit fournir au Fournisseur un emplacement conforme
              à la réglementation environnementale, sur son chantier pour laver
              son camion et ses équipements.
            </li>
          </ol>
          <li>
            <strong className="underline">11 BORDEREAU DE LIVRAISON</strong>
          </li>
          <ol>
            <li>
              11.1 L’absence de signature du Client au bordereau de livraison
              équivaudra à l’acceptation dudit bordereau sans restriction par le
              Client;
            </li>
            <li>
              11.2 Le Client indiquera par écrit au bordereau de livraison tout
              désaccord, notamment quant à la prescription, au type et à la
              qualité du béton, à défaut de quoi le béton livré sera réputé
              accepté par le Client.
            </li>
          </ol>
          <li>
            <strong className="underline">12 DÉNONCIATION</strong>
          </li>
          <ol>
            <li>
              12.1 Le Client, comprend et accepte que le Fournisseur dénonce son
              contrat de fourniture de matériau au propriétaire, à la caution ou
              à toute autre personne à qui le Fournisseur est tenu de dénoncer
              en vertu de la Loi.
            </li>
          </ol>
          <li>
            <strong className="underline">13 INTERPRÉTATION</strong>
          </li>
          <ol>
            <li>
              13.1 Les présentes sont régies par les lois en vigueur dans la
              province de Québec et celles du Canada qui s’y appliquent.
            </li>
          </ol>
        </ol>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex items-center">
              <strong className="w-2/5">{t("client_acceptation")}</strong>
            </Grid>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("date")}
              </Typography>
              <DatePickerFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-3/5"
                name="clientAcceptanceDate"
                id="clientAcceptanceDate"
                variant="outlined"
                fullWidth
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                autoOk
                label={t("date")}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

export default GeneralConditions;
