const portalConfig = {
  companyName: "Beton Chevalier",
  longName: "Portail Beton Chevalier",
  loginDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque blandit lacus sit amet velit maximus, non bibendum ante elementum. Aliquam ut augue sodales dolor posuere eleifend. Praesent pharetra ipsum quis quam ultrices fermentum. Proin accumsan ac sapien sed gravida.",
  allowLBUN: false, // Allow login by Username
  showCompanyNameInSidebar: false, // if to show company name next to the logo
  colors: {
    main: "#1852A4",
    gradient: "#9B56AB"
  },
  multilang: true,
  defaultLanguage: "fr-CA",
  languages: [
    {
      id: "fr-CA",
      title: "Français (FR)",
      shortName: "FR",
      flag: "fr"
    }
  ]
};

export default portalConfig;
