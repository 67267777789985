import ResetPasswordPage from "./ResetPasswordPage";
import i18next from "i18next";

import fr_FR from "./i18n/fr_FR";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "resetPasswordApp", fr_FR);
i18next.addResourceBundle("en-CA", "resetPasswordApp", en_CA);

const ResetPasswordPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  auth: "onlyGuest",
  routes: [
    {
      path: "/auth/reset-password/:token",
      component: ResetPasswordPage
    }
  ]
};

export default ResetPasswordPageConfig;
