import { DetailedHTMLProps, TimeHTMLAttributes } from "react";

interface DateFormatterProps
  extends DetailedHTMLProps<
    TimeHTMLAttributes<HTMLTimeElement>,
    HTMLTimeElement
  > {
  date: Date | string | number;

  time?: boolean;

  className?: string;
}

function DateFormatter(props: DateFormatterProps) {
  const { date, time, className, ...otherProps } = props;

  let dateFormatted: Date;

  if (isValidDate(date)) {
    dateFormatted = new Date(date);
  } else if (typeof date === "string" && parseInt(date)) {
    dateFormatted = new Date(parseInt(date));
  } else {
    return <span className="text-grey italic">--</span>;
  }

  const options: Intl.DateTimeFormatOptions = {};

  if (time === true) {
    options.year = "numeric";

    options.month = "numeric";

    options.day = "numeric";

    options.hour = "numeric";

    options.minute = "numeric";

    // options.second = "numeric";

    options.timeZone = "Europe/Helsinki";
  }

  const d = new Intl.DateTimeFormat("fr-BF", options).format(dateFormatted);

  return (
    <time
      {...otherProps}
      dateTime={dateFormatted.toISOString()}
      className={className}
    >
      {d}
    </time>
  );
}

function isValidDate(dateObject: string | number | Date) {
  if (!dateObject) return false;

  return new Date(dateObject).toString() !== "Invalid Date";
}

export default DateFormatter;
