import React from "react";

export function generateRoutesFromConfigs(configs, defaultAuth) {
  let allRoutes = [];
  for (const config of configs) {
    allRoutes.push(...setRoutes(config, defaultAuth));
  }
  return allRoutes;
}

export function setRoutes(config, defaultAuth) {
  let routes = [...config.routes];

  if (config.settings || config.auth) {
    routes = routes.map((route) => {
      let auth = null;
      if (route.auth) {
        auth = route.auth;
      } else {
        auth = config.auth ? config.auth : defaultAuth || null;
        auth = route.auth ? [...auth, ...route.auth] : auth;
      }

      const Element = route.element || route.component;
      if (Element?.$$typeof?.toString() === "Symbol(react.lazy)")
        route.element = <Element />;
      else if (typeof Element === "function") route.element = <Element />;
      else route.element = Element;

      if (route.component) delete route.component
      
      return {
        ...route,
        settings: { ...config.settings, ...route.settings },
        auth,
      };
    });
  }

  return [...routes];
}
