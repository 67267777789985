import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "app/auth/store/actions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function UserMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        classes={{
          root: "w-52 md:w-auto md:px-8 h-52 p-0 min-w-0",
          label: "flex gap-8"
        }}
        onClick={userMenuClick}
      >
        {user.data.photoURL ? (
          <Avatar
            className="h-40 w-40"
            alt={user.data.displayName}
            src={user.data.photoURL}
          />
        ) : (
          <Avatar className="h-40 w-40">{user.data.displayName[0]}</Avatar>
        )}

        <div className="hidden md:block text-left">
          <div className="normal-case font-600 -mb-4">
            {user.data.displayName}
          </div>
          <div className="text-11 normal-case text-gray-400">
            {user.data.level && user.data.level.name}
          </div>
        </div>

        <Icon
          className="text-16 hidden md:block text-gray-500"
          variant="action"
        >
          keyboard_arrow_down
        </Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          paper: "py-8"
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/login">
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("login")} />
            </MenuItem>
            <MenuItem component={Link} to="/register">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("register")} />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={Link}
              to="/users/profile"
              onClick={userMenuClose}
            >
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("myprofile")} />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/app/customer"
              onClick={userMenuClose}
            >
              <ListItemIcon className="min-w-40">
                <Icon>info</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("factory_info")} />
            </MenuItem>
            {/* <MenuItem
              component={Link}
              to="/customer_account"
              onClick={userMenuClose}
            >
              <ListItemIcon className="min-w-40">
                <Icon>info</Icon>
              </ListItemIcon>
              <ListItemText
                className="pl-0"
                primary={t("open_a_new_account")}
              />
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                dispatch(authActions.logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("logout")} />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
