import { lazy } from "react";
import i18next from "i18next";
import fr_FR from "./i18n/fr_FR";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "agreements", fr_FR);
i18next.addResourceBundle("en-CA", "agreements", en_CA);

const config = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "agreement",
  auth: "login",
  routes: [
    {
      path: "/app/agreements/list",
      component: lazy(() => import("./views/List")),
    },
    {
      path: "/app/agreements/item/:itemId",
      component: lazy(() => import("./views/Item")),
    },
  ],
};

export default config;
