import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import NavbarMobileToggleButton from "app/fuse-layouts/shared-components/NavbarMobileToggleButton";
import QuickPanelToggleButton from "app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton";
import UserMenu from "app/fuse-layouts/shared-components/UserMenu";
import { useSelector } from "react-redux";
import IFrameUrlCopier from "app/fuse-layouts/shared-components/IFrameUrlCopier";
import LanguageSwitcher from "app/fuse-layouts/shared-components/LanguageSwitcher";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  separator: {
    width: 1,
    height: 26
  }
}));

function ToolbarLayout1(props) {
  const classes = useStyles(props);

  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className="flex relative z-10 shadow-0 border-b border-b-gray-300"
        color="default"
      >
        <Toolbar className="px-1 py-1 min-h-0">
          {config.navbar.display && config.navbar.position === "left" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton className="w-52 h-52 p-0 m-0 rounded-md" />
            </Hidden>
          )}

          <div className="flex flex-1">
            <IFrameUrlCopier />
          </div>
          {/* <NotificationPanelToggleButton /> */}

          <div className="flex items-center gap-1">
            <UserMenu />
            <div className={clsx("bg-gray-200", classes.separator)} />

            <LanguageSwitcher />
            <div className={classes.separator} />

            {/* 
						<FuseSearch/> 
						<Hidden lgUp>
							<div className={classes.separator}/>
						</Hidden> 
						<div className={classes.separator} />
						*/}

            <QuickPanelToggleButton />
          </div>

          {config.navbar.display && config.navbar.position === "right" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton />
            </Hidden>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default ToolbarLayout1;
