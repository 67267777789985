import Register from "./Register";

const config = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  auth: "login",
  routes: [
    {
      path: "/auth/finalize-auth/:encodedToken",
      component: Register
    }
  ]
};

export default config;
