import React from "react";
import { gql, useQuery } from "@apollo/client";
import ErrorComponent from "@exiry/components/Error";
import LoadingComponent from "@exiry/components/Loading";
import UserNotification from "./UserNotification";

export const USER_NOTIFICATIONS = gql`
  query LoggedUserNotification {
    LoggedUserNotification {
      id
      meta
      slug
      userId
      notifiedAt
      createdAt
    }
  }
`;

interface Query {
  LoggedUserNotification: LoggedUserNotification[];
}

export interface LoggedUserNotification {
  id: string;
  meta: string;
  slug: string;
  userId: string;
  notifiedAt: any;
  createdAt: number;
}

export default function UserNotificationList() {
  const { data, loading, error, refetch } = useQuery<Query>(USER_NOTIFICATIONS);

  const notificationList = data?.LoggedUserNotification || [];

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent retry={refetch} />;
  return (
    <ol className="relative border-l border-gray-200 dark:border-gray-700">
      {notificationList.map((notification) => (
        <UserNotification notification={notification} key={notification.id} />
      ))}
    </ol>
  );
}
