import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ExiryWindowObject {
  history?: string[];
}

declare global {
  interface Window {
    exiry?: ExiryWindowObject;
  }
}

if (!window.exiry) window.exiry = {} as ExiryWindowObject;

function useHistoryLog() {
  const location = useLocation();

  useEffect(() => {
    if (!Array.isArray(window.exiry.history)) window.exiry.history = [];
    window.exiry.history.push(location.pathname);
  }, [location]);
}

export default useHistoryLog;
