// ProprietairesEntreprise.js
import React from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import { useTranslation } from "react-i18next";
import DatePickerFormsy from "@exiry/components/formsy/DatePickerFormsy";

function PersonalBond() {
  const { t } = useTranslation("customer");
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          className="underline"
          margin="20px"
          align="center"
        >
          {t("personal_guarantee")}
        </Typography>

        <Typography variant="subtitle1">
          Ayant lu et compris les termes et conditions des présentes, j’ (nous)
          accepte (ons) et je (nous) déclare (ons) me (nous) porter caution(s)
          solidaire(s) avec l’entreprise vis-à-vis la (ou les) compagnie(s)
          ci-avant sélectionnée(s), aux paiements de toute et chacune des sommes
          dues en capital, intérêts, pénalités et frais et renonce (ons)
          expressément aux bénéfices de discussion, de division et de
          subrogation.
        </Typography>
        <Typography variant="subtitle1">
          Je (nous), soussigné(s), tant à titre de représentant(s) de
          l’entreprise qu’en mon (notre) nom personnel, certifie (ions) que les
          informations contenues aux présentes sont exactes et complètes et
          déclare (ons) être au courant du sens et de la portée du présent
          document et du cautionnement et à m’y (nous y) conformer de même
          qu’être autorisé(s) à solliciter l’ouverture d’un compte auprès de la
          (ou les) compagnie(s) ci-avant sélectionnée(s).
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("signature_day")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteeSignedDay"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("signature_month")}
            </Typography>
            <SelectFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteeSignedMonth"
              variant="outlined"
              fullWidth
            >
              {months.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </SelectFormsy>
          </Grid>
          <Grid item xs={4} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("signature_year")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteeSignedYear"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("personalGuaranteePrintedName")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteePrintedName"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("birthday")}
            </Typography>
            <DatePickerFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteeDateOfBirth"
              id="personalGuaranteeDateOfBirth"
              variant="outlined"
              fullWidth
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              autoOk
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("personalGuaranteeTitle")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteeTitle"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("signature")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("personalGuaranteePrintedName")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteePrintedName2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("birthday")}
            </Typography>{" "}
            <DatePickerFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteeDateOfBirth2"
              id="personalGuaranteeDateOfBirth2"
              variant="outlined"
              fullWidth
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              autoOk
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("personalGuaranteeTitle")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="personalGuaranteeTitle2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("signature")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PersonalBond;
