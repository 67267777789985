import { useRef, useState } from "react";
import { Button, InputAdornment, Icon } from "@material-ui/core";
import TextFieldFormsy from "@fuse/components/formsy/TextFieldFormsy";
import Formsy from "formsy-react";
import * as Actions from "app/auth/store/actions/login.actions";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import { useMutation, gql } from "@apollo/client";
import { loginDataFields } from "app/main/users/graphql/UserLoginData";
import jwtService from "app/services/jwtService";
import { handleException } from "@exiry/helpers/error.helpers";

const LOGIN_MUTATION = gql`
  ${loginDataFields}
  mutation Login($email: String!, $password: String!) {
    loginByCC(email: $email, password: $password) {
      ...LoginDataFields
    }
  }
`;

function JWTLoginTab() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [exec, { loading }] = useMutation(LOGIN_MUTATION);

  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef(null);

  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    setIsFormValid(true);
  }

  function handleSubmit(model) {
    const { password } = model;
    // Trim all white space from the email input
    const email = model.email.replace(/\s/g, "");
    exec({ variables: { email, password } })
      .then(({ data }) => {
        const { loginByCC } = data;
        if (loginByCC?.user && loginByCC?.token) {
          jwtService.setSession(loginByCC?.token);
          // @ts-ignore
          dispatch(Actions.loginSuccess(loginByCC?.user));
        }
      })
      .catch((error) => {
        handleException(error);
        if (error.graphQLErrors[0]?.extensions?.code.length > 0) {
          let errorMessage =
            "error.server_side." + error.graphQLErrors[0]?.extensions?.code;
          if (error.graphQLErrors[0]?.message === "User is not found") {
            errorMessage = "error.server_side.undefined_user";
          }
          if (error.graphQLErrors[0]?.message === "User is not a client") {
            errorMessage = "error.server_side.not_customer";
          }
          if (error.graphQLErrors[0]?.message === "BAD_USER_PASSWORD") {
            errorMessage = "error.server_side.BAD_USER_PASSWORD";
          }
          formRef.current?.updateInputsWithError({
            password: t(errorMessage)
          });
        }
      });
  }

  // Reset error when changing any component of the form
  function handelChange() {
    formRef.current.updateInputsWithError({
      email: null,
      password: null
    });
  }

  return (
    <div className="w-full">
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        onChange={handelChange}
        ref={formRef}
        className="flex flex-col justify-center w-full"
      >
        <TextFieldFormsy
          className="mb-16"
          type="email"
          name="email"
          label="Email"
          id="login-email"
          value=""
          validations={{
            isEmail: true
          }}
          validationErrors={{
            isEmail: t("error.form.email")
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  email
                </Icon>
              </InputAdornment>
            )
          }}
          variant="outlined"
          required
        />

        <TextFieldFormsy
          className="mb-16"
          type="password"
          name="password"
          label="Password"
          id="login-password"
          value=""
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  vpn_key
                </Icon>
              </InputAdornment>
            )
          }}
          variant="outlined"
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          id="login-button"
          className="w-full mx-auto mt-16 normal-case"
          aria-label={t("login")}
          disabled={!isFormValid || loading}
          value="legacy"
        >
          {loading && <CircularProgress className="mr-16" size={15} />}
          {t("login")}
        </Button>
      </Formsy>
    </div>
  );
}

export default JWTLoginTab;
