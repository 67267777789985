import React from "react";

interface AppContextInterface {
  // Create the router type
  routes: any[];
}

const AppContext = React.createContext<AppContextInterface>({ routes: [] });

export default AppContext;
