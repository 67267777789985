import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

export default function Loading() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-col items-center justify-center h-full p-32">
      <Typography className="text-20 mb-16" color="textSecondary">
        {t("loading")}
      </Typography>
      <LinearProgress className="w-xs" color="secondary" />
    </div>
  );
}
