import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import FuseAnimate from "@fuse/components/FuseAnimate/FuseAnimate";
import { Link } from "react-router-dom";
import clsx from "clsx";
import JWTRegisterTab from "./tabs/JWTRegisterTab";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: "linear-gradient(45deg, #C82027, #B71B5B);",
    color: theme.palette.primary.contrastText
  }
}));

function Register() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-1 shrink-0 p-24 md:flex-row md:p-0 h-screen"
      )}
    >
      <div className="  flex flex-col grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:shrink-0 md:flex-1 md:text-left sticky-content">
        {/* @ts-ignore */}
        <FuseAnimate animation="transition.expandIn">
          <img
            className="w-200 mb-32"
            src="assets/logos/logo-b.svg"
            alt="Logo"
          />
        </FuseAnimate>
        {/* @ts-ignore */}
        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <Typography variant="h2" color="inherit" className="font-light">
            Bienvenue
          </Typography>
        </FuseAnimate>

        {/* @ts-ignore */}
        <FuseAnimate delay={400}>
          <Typography
            variant="subtitle1"
            color="inherit"
            className="max-w-512 mt-16"
          >
            Béton Chevalier vous offre tous types de béton, tant pour vos
            projets résidentiels que vos projets commerciaux, de petite et de
            très grandes envergures.
          </Typography>
        </FuseAnimate>
      </div>

      {/* @ts-ignore */}
      <FuseAnimate animation={{ translateX: [0, "100%"] }}>
        <Card
          className="w-full max-w-1/2 mx-auto m-16 md:m-0 overflow-y-auto"
          square
        >
          <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 ">
            <JWTRegisterTab />
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  );
}

export default Register;
