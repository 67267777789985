import { gql, useMutation } from "@apollo/client";
import DateFormatter from "@exiry/components/formatters/DateFormatter";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import {  useNavigate } from "react-router-dom";
import { LoggedUserNotification } from "./UserNotificationList";

export const MARK_NOTIFICATION_SEEN = gql`
  mutation MarkUserNotificationSeen($id: ID!) {
    markUserNotificationSeen(id: $id) {
      id
      notifiedAt
    }
  }
`;

interface Props {
  notification: LoggedUserNotification;
}
export default function UserNotification({ notification }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let meta = {} as Record<string, string>;
  try {
    meta = JSON.parse(notification.meta);
  } catch (error) {
    if (process.env.NODE_ENV !== "production") console.log(error);
  }

  let isSeen: boolean;
  if (!notification.notifiedAt) {
    isSeen = false;
  } else {
    isSeen = true;
  }
  const [mutate] = useMutation(MARK_NOTIFICATION_SEEN);
  async function handleSubmit() {
    mutate({
      variables: {
        id: notification.id,
      },
    }).then(() => {
        navigate(`/app/ticket/details/${meta.ticketId}/info`)
    });
  }
  return (
    <li
      className={clsx("mb-12 ml-12  ", {
        "border border-gray-100- bg-gray-100": !isSeen,
      })}
      onClick={() => handleSubmit()}
    >
      <div className="absolute w-12 h-12 bg-gray-200 rounded-full mt-6 -left-6 border border-white dark:border-gray-900 dark:bg-gray-700"></div>

      <DateFormatter
        date={notification.createdAt}
        className="mb-6 text-xs font-normal leading-none text-gray-400 dark:text-gray-500"
      />
      <p className="text-12 font-normal text-gray-500 dark:text-gray-400">
        {t(
          `user_notification_slug.${notification.slug}${
            !notification.userId ? "_cron" : ""
          }`,
          meta
        )}
      </p>
    </li>
  );
}
