import { Card, CardContent, Typography } from "@material-ui/core";
import FuseAnimate from "@fuse/components/FuseAnimate/FuseAnimate";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import portalConfig from "../../../configs/portalConfig";
import JWTLoginTab from "./tabs/JWTLoginTab";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme: any) => ({
  root: {
    background: "linear-gradient(45deg, #C82027, #B71B5B);",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: theme.palette.primary.contrastText
  },
  h3welcome: {
    margin: "0px 0px 30px 0px"
  },
  pictogram: {
    maxWidth: "700px",
    margin: "50px 0px"
  },
  title: {
    textTransform: "uppercase"
  }
}));

function Login() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-1 shrink-0 p-24 md:flex-row md:p-0"
      )}
    >
      <div className="hidden md:flex flex-col grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:shrink-0 md:flex-1 md:text-left">
        {/* @ts-ignore */}
        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <Typography
            variant="h3"
            color="inherit"
            className={clsx(classes.h3welcome, "font-light")}
          >
            <br />
            <strong>Béton Chevalier</strong> !
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={400}>
          <div className="max-w-512">
            <Typography variant="subtitle1" color="inherit" className="mt-16">
              Béton Chevalier vous offre tous types de béton, tant pour vos
              projets résidentiels que vos projets commerciaux, de petite et de
              très grandes envergures.
            </Typography>
          </div>
        </FuseAnimate>
      </div>

      {/* @ts-ignore */}
      <FuseAnimate animation={{ translateX: [0, "100%"] }}>
        <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
          <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
            <img
              className="w-200 mb-32"
              src="/assets/logos/logo.svg"
              alt="Logo"
            />
            <Typography
              variant="h6"
              className={clsx(classes.title, "text-center md:w-full mb-48")}
            >
              {t("login")}
            </Typography>

            <JWTLoginTab />

            <div className="flex flex-col items-center justify-center pt-32">
              <Link
                className="font-medium mt-8"
                id="forgot-password-link"
                to="/auth/forgot-password"
              >
                Mot de passe oublié ?
              </Link>
            </div>
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  );
}

export default Login;
