import store from "./store";
import Provider from "react-redux/es/components/Provider";
import routes from "./configs/routesConfig";
import AppContext from "./AppContext";
import { Auth } from "./auth";
import { ApolloProvider } from "@apollo/client";
import client from "./services/apolloClient";
import { ComponentType } from "react";

function withAppProviders<T extends {} = {}>(Component: ComponentType<T>) {
  return (props: T = {} as T) => {
    const WrapperComponent = () => (
      <AppContext.Provider value={{ routes }}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <Auth>
              <Component {...props} />
            </Auth>
          </Provider>
        </ApolloProvider>
      </AppContext.Provider>
    );

    return WrapperComponent;
  };
}

export default withAppProviders;
