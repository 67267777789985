import { setInitialSettings } from "app/store/actions/fuse/settings.actions";
import _ from "@lodash";
export const SET_USER_DATA = "[USER] SET DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const EDIT_PASSWORD_SUCCESS = "[USER] EDIT PASSWORD SUCCESS";
export const EDIT_PASSWORD_ERROR = "[USER] EDIT PASSWORD ERROR";
export const SET_USER_PROFILE_PICTURE = "[USER] Update user profile picture";
export const UPDATE_USER_ACCESSES = "[USER] Update user accesses";

/**
 * Set User Data
 */
export function setUserData(user) {
  return (dispatch) => {
    /**
     * Set User Settings
     */
    const privileges = {
      login: {
        slug: "login",
        name: "User connected",
        can_view: true,
        can_view_own: true,
        can_view_bu: true,
        can_edit: true,
        can_create: true,
        can_delete: true,
      },
    };
    for (const privilege of user.role?.privileges?.nodes || []) {
      privileges[privilege.access.slug] = {
        slug: privilege.access.slug,
        name: privilege.access.name,
        can_view: privilege.canView === true,
        can_view_own: privilege.canViewOwn === true,
        can_view_bu: privilege.canViewBu === true,
        can_edit: privilege.canEdit === true,
        can_create: privilege.canCreate === true,
        can_delete: privilege.canDelete === true,
      };
    }
    const userData = {
      role: privileges,
      data: {
        displayName: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
        photoURL: user.profileImage,
        email: user.email,
        role: user.role,
        level: {
          id: user.id,
          name: user.name,
        },
        // settings   : (tokenData.user_metadata && tokenData.user_metadata.settings) ? tokenData.user_metadata.settings : {},
        // shortcuts  : (tokenData.user_metadata && tokenData.user_metadata.shortcuts) ? tokenData.user_metadata.shortcuts : []
      },
    };

    // Set User Data
    dispatch({
      type: SET_USER_DATA,
      payload: userData,
    });
  };
}

/**
 * Update user accesses in the store
 */
export function updateAccesses(accesses) {
  const privileges = {};
  for (const privilege of accesses.privileges?.nodes || []) {
    privileges[privilege.access.slug] = {
      slug: privilege.access.slug,
      name: privilege.access.name,
      can_view: privilege.canView === true,
      can_view_own: privilege.canViewOwn === true,
      can_view_bu: privilege.canViewBu === true,
      can_edit: privilege.canEdit === true,
      can_create: privilege.canCreate === true,
      can_delete: privilege.canDelete === true,
    };
  }
  return {
    type: UPDATE_USER_ACCESSES,
    payload: privileges,
  };
}

/**
 * Update user profile picture
 */
export function updateUserProfilePicture(profilePicture) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    user.data.photoURL = profilePicture;
    // Set User Data
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { data: { settings } });

    updateUserData(user);

    return dispatch(setUserData(user));
  };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    updateUserData(newUser);

    return dispatch(setUserData(newUser));
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Logout
 */
export function logoutUser() {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (!user.role || user.role.length === 0) {
      // is guest
      dispatchLogout(dispatch);
      return null;
    }
    // jwtService.logout();
    // To render it a module
    localStorage.removeItem("jwt_access_token");
    dispatchLogout(dispatch);
  };
}

function dispatchLogout(dispatch) {
  dispatch(setInitialSettings());
  dispatch({ type: USER_LOGGED_OUT });
}

/**
 * Update User Data
 */
function updateUserData(user) {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
}
