import React, { useState, useLayoutEffect, useContext } from "react";
import PropTypes from "prop-types";
import FuseUtils from "@fuse/FuseUtils";
import AppContext from "app/AppContext";
import { useSelector } from "react-redux";
import useHistoryLog from "@exiry/hooks/useHistoryLog";
import { useNavigate, useLocation, matchRoutes } from "react-router";

function ExiryAuthorizationWrapper(props) {
  useHistoryLog();

  const navigate = useNavigate();
  const location = useLocation();

  const { routes } = useContext(AppContext);
  const userRole = useSelector(({ auth }) => auth.user.role);

  const [accessGranted, setAccessGranted] = useState(true);

  useLayoutEffect(() => {
    if (!accessGranted) redirectRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessGranted]);

  useLayoutEffect(() => {
    const { pathname } = location;
    if (routes) {
      const matches = matchRoutes(routes, pathname);
      if (matches?.[0]) {
        let hasPermission = true;
        if (matches[0])
          hasPermission = FuseUtils.hasPermission(
            matches[0].route.auth,
            userRole
          );
        setAccessGranted(hasPermission);
      }
    }
  }, [location, routes, userRole]);

  const redirectRoute = () => {
    const { pathname, state } = location;
    const redirectUrl = state && state.redirectUrl ? state.redirectUrl : "/";
    // if user is guest, redirect to Login Page
    if (!userRole || userRole === "") {
      navigate({
        pathname: "/login",
        state: { redirectUrl: pathname },
      });
    } else {
      navigate({ pathname: redirectUrl });
    }
  };

  // TODO add a unauthorized component
  if (!accessGranted) return null;

  return <>{props.children}</>;
}

ExiryAuthorizationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExiryAuthorizationWrapper;
