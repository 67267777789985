import dashboardConfig from "./dashboard";
import projectConfig from "./projects";
import agreementConfig from "./agreements";
import invoiceConfig from "./invoices";
import ticketsConfig from "./tickets";

export const PortalConfigs = [
  dashboardConfig,
  projectConfig,
  agreementConfig,
  invoiceConfig,
  ticketsConfig
];
