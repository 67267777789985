import { lazy } from "react";
import i18next from "i18next";
import fr_FR from "./i18n/fr_FR";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "invoices", fr_FR);
i18next.addResourceBundle("en-CA", "invoices", en_CA);

const config = {
  settings: {
    layout: {
      config: {}
    }
  },
  name: "invoice",
  auth: "login",
  routes: [
    {
      path: "/app/invoices/list",
      component: lazy(() => import("./views/List"))
    }
  ]
};

export default config;
