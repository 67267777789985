import DateFnsUtils from "@date-io/date-fns";
import AuthorizationWrapper from "@exiry/components/AuthorizationWrapper";
import FuseLayout from "@fuse/components/FuseLayout/FuseLayout";
import FuseTheme from "@fuse/components/FuseTheme/FuseTheme";
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { create } from "jss";
import jssExtend from "jss-plugin-extend";
import rtl from "jss-rtl";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import fr from "javascript-time-ago/locale/fr";
import withAppProviders from "./withAppProviders";

JavascriptTimeAgo.addLocale(en);
JavascriptTimeAgo.addLocale(fr);

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const generateClassName = createGenerateClassName();

const App = () => {
  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BrowserRouter>
          <AuthorizationWrapper>
            <FuseTheme>
              <FuseLayout />
            </FuseTheme>
          </AuthorizationWrapper>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
};

export default withAppProviders(App)();
