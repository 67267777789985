// ProprietairesEntreprise.js
import React from "react";
import {
  Grid,
  Typography
  // ... autres importations nécessaires
} from "@material-ui/core";
import { TextFieldFormsy } from "@fuse";
import { useTranslation } from "react-i18next";

function BankInformation() {
  const { t } = useTranslation("customer");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          className="underline"
          align="center"
          margin="20px"
        >
          RÉFÉRENCES BANCAIRES ET FOURNISSEURS
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("financialInstitution")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="financialInstitution"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("noTransit")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="noTransit"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("institutionContact")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="institutionContact"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("accountNumber")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="accountNumber"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flex items-center">
        <Typography className="w-1/5" variant="subtitle1">
          {t("address")}
        </Typography>
        <TextFieldFormsy
          required
          validationError={t("translation:error.form.required")}
          margin="dense"
          className="w-4/5"
          name="bankAddress"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("city")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="bankCity"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("postalCode")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="bankPostalCode"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("telephone")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="bankPhone"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("Fax")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="bankFax"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flex items-center">
        <Typography className="w-1/5" variant="subtitle1">
          1. {t("supplier")}
        </Typography>
        <TextFieldFormsy
          required
          validationError={t("translation:error.form.required")}
          margin="dense"
          className="w-4/5"
          name="supplier1"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className="flex items-center">
        <Typography className="w-1/5" variant="subtitle1">
          {t("address")}
        </Typography>
        <TextFieldFormsy
          required
          validationError={t("translation:error.form.required")}
          margin="dense"
          className="w-4/5"
          name="supplierAddress1"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("telephone")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="supplierPhone1"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("Fax")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="supplierFax1"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flex items-center">
        <Typography className="w-1/5" variant="subtitle1">
          {t("supplier_contact")}
        </Typography>
        <TextFieldFormsy
          required
          validationError={t("translation:error.form.required")}
          margin="dense"
          className="w-4/5"
          name="supplierContact1"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className="flex items-center">
        <Typography className="w-1/5" variant="subtitle1">
          2. {t("supplier")}
        </Typography>
        <TextFieldFormsy
          required
          validationError={t("translation:error.form.required")}
          margin="dense"
          className="w-4/5"
          name="supplier2"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className="flex items-center">
        <Typography className="w-1/5" variant="subtitle1">
          {t("address")}
        </Typography>
        <TextFieldFormsy
          required
          validationError={t("translation:error.form.required")}
          margin="dense"
          className="w-4/5"
          name="supplierAddress2"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("telephone")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="supplierPhone2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("Fax")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="supplierFax2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Typography className="w-1/5" variant="subtitle1">
              {t("supplier_contact")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-4/5"
              name="supplierContact2"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BankInformation;
