import Denounciation from "./Denounciation";

import fr_FR from "./i18n/fr_FR.json";
import en_CA from "./i18n/en_CA.json";
import i18next from "i18next";

i18next.addResourceBundle("fr-CA", "denounciation", fr_FR);
i18next.addResourceBundle("en-CA", "denounciation", en_CA);

// Récupérer le token encodé à partir de l'URL

const config = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  auth: "onlyGuest",
  routes: [
    {
      path: "/auth/denounciation/:encodedToken",
      component: Denounciation
    }
  ]
};

export default config;
