import React from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { SelectFormsy, TextFieldFormsy } from "@fuse";
import { useTranslation } from "react-i18next";
import DatePickerFormsy from "@exiry/components/formsy/DatePickerFormsy";

function CreditCardAuthorization() {
  const { t } = useTranslation("customer");

  const months = Array.from(Array(12), (_, index) => (index + 1).toString());

  return (
    <Grid container spacing={2}>
      <div>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            className="underline"
            margin="20px"
            align="center"
          >
            {t("credit_card_payment_authorization")}
          </Typography>
        </Grid>
        <Typography variant="subtitle1">
          Nous autorisons la (ou les) compagnie(s) ci-avant sélectionnée(s) à
          prélever de notre carte de crédit tout montant exigible suivant
          l’émission de toute facture. Nous nous engageons à informer
          immédiatement la (ou les) compagnie(s) ci-avant sélectionnée(s) dans
          l’éventualité où cette carte de crédit ne serait plus valide. La
          présente autorisation peut être annulée à tout moment moyennant un
          avis écrit de 30 jours.
        </Typography>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                Type carte:
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup row name="creditCardType">
                  <FormControlLabel
                    control={<Radio />}
                    value="VISA"
                    label="Visa"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="MasterCard"
                    label="MasterCard"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("cardNumber")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-3/5"
                name="cardNumber"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("expiration")}
              </Typography>
              <SelectFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-3/5"
                name="creditCardExpirationMonth"
                variant="outlined"
                fullWidth
              >
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {t(`calendar:month_${month}`)}
                  </MenuItem>
                ))}
              </SelectFormsy>
            </Grid>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("calendar:year_one")}
              </Typography>
              <TextFieldFormsy
                required
                margin="dense"
                className="w-3/5"
                name="creditCardExpirationYear"
                variant="outlined"
                fullWidth
                validations={{
                  matchRegexp: /(?:(?:19|20)[0-9]{2})/
                }}
                validationError={t("translation:error.form.required")}
                validationErrors={{
                  matchRegexp: t("translation:error.form.value_not_supported")
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="flex items-center">
          <Typography className="w-2/5" variant="subtitle1">
            {t("cardholder_name")}
          </Typography>
          <TextFieldFormsy
            required
            validationError={t("translation:error.form.required")}
            margin="dense"
            className="w-3/5"
            name="creditCardHolderName"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className="flex items-center">
          <Typography className="w-2/5" variant="subtitle1">
            {t("signature")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("role")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-3/5"
                name="role2"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("date")}
              </Typography>
              <DatePickerFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-3/5"
                name="signatureDate2"
                id="signatureDate2"
                variant="outlined"
                fullWidth
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                autoOk
                label={t("date")}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

export default CreditCardAuthorization;
