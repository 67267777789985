import { useCallback, useContext, useMemo, useRef } from "react";
import { withStyles } from "@material-ui/core";
import FuseLayouts from "../../../app/fuse-layouts/FuseLayouts";
import _ from "@lodash";
import { matchRoutes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSettings } from "app/store/actions/fuse/settings.actions";
import AppContext from "app/AppContext";
import { generateSettings } from "app/store/reducers/fuse/settings.reducer";
import useDeepCompareEffect from "@exiry/hooks/useDeepCompareEffect";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& code:not([class*="language-"])': {
      color: theme.palette.secondary.dark,
      backgroundColor: "#F5F5F5",
      padding: "2px 3px",
      borderRadius: 2,
      lineHeight: 1.7,
    },
    "& table.simple tbody tr td": {
      borderColor: theme.palette.divider,
    },
    "& table.simple thead tr th": {
      borderColor: theme.palette.divider,
    },
    "& a:not([role=button])": {
      color: theme.palette.secondary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
});

function FuseLayout(props) {
  const { classes } = props;
  const { routes } = useContext(AppContext);

  const location = useLocation();
  const dispatch = useDispatch();

  const newSettings = useRef(null);

  const settings = useSelector(({ fuse }) => fuse.settings.current);
  const defaultSettings = useSelector(({ fuse }) => fuse.settings.defaults);

  const matchedRoutes = matchRoutes(routes, location.pathname);
  const matched = matchedRoutes ? matchedRoutes[0] : false;

  const shouldAwaitRender = useCallback(() => {
    let _newSettings;
    if (matched?.route?.settings) {
      const routeSettings = matched.route.settings;
      _newSettings = generateSettings(defaultSettings, routeSettings);
    } else if (!_.isEqual(newSettings.current, defaultSettings)) {
      /**
       * Reset to default settings on the new path
       */
      _newSettings = _.merge({}, defaultSettings);
    } else {
      _newSettings = newSettings.current;
    }
    if (!_.isEqual(newSettings.current, _newSettings)) {
      newSettings.current = _newSettings;
    }
  }, [defaultSettings, matched]);

  shouldAwaitRender();

  useDeepCompareEffect(() => {
    if (!_.isEqual(newSettings.current, settings)) {
      dispatch(setSettings(newSettings.current));
    }
  }, [dispatch, newSettings.current, settings]);

  const Layout = useMemo(
    () => FuseLayouts[settings.layout.style],
    [settings.layout.style]
  );

  return _.isEqual(newSettings.current, settings) ? (
    <Layout classes={{ root: classes.root }} {...props} />
  ) : null;
}

export default withStyles(styles, { withTheme: true })(FuseLayout);
