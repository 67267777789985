import { lazy } from "react";
import i18next from "i18next";
import fr_FR from "./i18n/fr_FR";
import en_CA from "./i18n/en_CA";
import { Navigate } from "react-router-dom";

i18next.addResourceBundle("fr-CA", "projects", fr_FR);
i18next.addResourceBundle("en-CA", "projects", en_CA);

const config = {
  settings: {
    layout: {
      config: {}
    }
  },
  name: "project",
  auth: "login",
  routes: [
    {
      path: "/app/projects/list",
      component: lazy(() => import("./views/List"))
    },
    {
      path: "/app/projects/item/:itemId",
      component: lazy(() => import("./views/Item"))
    },
    {
      path: "/app/projects",
      component: () => <Navigate to="/app/projects/list" />
    }
  ]
};

export default config;
