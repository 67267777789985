import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FuseSplashScreen from "@fuse/components/FuseSplashScreen/FuseSplashScreen";
import * as userActions from "app/auth/store/actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import jwtService from "app/services/jwtService";
import { useMutation, gql } from "@apollo/client";
import { loginDataFields } from "app/main/users/graphql/UserLoginData";
import { showMessage } from "app/store/actions/fuse/";
import { handleException } from "@exiry/helpers/error.helpers";

const MUTATION_LOGIN = gql`
  ${loginDataFields}
  mutation loginByCc {
    loginByCC {
      ...LoginDataFields
    }
  }
`;

function Auth(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [signInWithToken] = useMutation(MUTATION_LOGIN);

  const [waitAuthCheck, setWait] = useState(true);

  useEffect(() => {
    const jwtCheck = () => {
      return new Promise((resolve, reject) => {
        const showError = (message) => {
          dispatch(
            showMessage({
              message: t([
                `error.server_side.${message}`,
                "error.server_side.unknown_server_error"
              ]),
              variant: "error"
            })
          );
        };
        jwtService.on("onAutoLogin", () => {
          signInWithToken()
            .then(({ data }) => {
              const { loginByCC: login } = data;
              jwtService.handleLogin(login.token);
              dispatch(userActions.setUserData(login.user));
              resolve(login.user);
            })
            .catch((error) => {
              handleException(error);
              showError(
                error.graphQLErrors && error.graphQLErrors[0]?.extensions?.code
              );
              jwtService.logout();
              dispatch(userActions.logoutUser());
              reject(error);
            });
        });

        jwtService.on("onAutoLogout", (message) => {
          if (message) showError(message);
          jwtService.logout();
          dispatch(userActions.logoutUser());
          resolve();
        });

        jwtService.on("onNoAccessToken", () => {
          resolve();
        });

        jwtService.init();

        return Promise.resolve();
      });
    };

    jwtCheck()
      .catch((error) => {
        handleException(error);
      })
      .finally(() => {
        setWait(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (waitAuthCheck) return <FuseSplashScreen />;

  return <>{props.children}</>;
}

Auth.propTypes = {
  children: PropTypes.node.isRequired
};

export default Auth;
