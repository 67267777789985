import React, { memo, useEffect } from "react";
import { withFormsy } from "formsy-react";
import _ from "@lodash";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { PassDownProps } from "formsy-react/dist/withFormsy";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

type OriginalProps = Omit<KeyboardDatePickerProps, "onChange" | "value"> & {
  onChange?: KeyboardDatePickerProps["onChange"];
};
type Props = PassDownProps<Date> & OriginalProps;

function DatePickerFormsy(props: Props) {
  const importedProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "autoOk",
    "children",
    "className",
    "disabled",
    "disableToolbar",
    "FormHelperTextProps",
    "format",
    "fullWidth",
    "id",
    "InputLabelProps",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "margin",
    "multiline",
    "name",
    "onBlur",
    "onChange",
    "onFocus",
    "placeholder",
    "required",
    "rows",
    "rowsMax",
    "select",
    "SelectProps",
    "type",
    "variant",
    "inputVariant",
  ]) as KeyboardDatePickerProps;

  // An error message is returned only if the component is invalid
  const errorMessage = props.errorMessage;
  const value = props.value || null;
  const defaultValue = props.defaultValue;

  useEffect(() => {
    if (defaultValue instanceof Date) {
      props.setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeValue(date: Date, value?: string) {
    props.setValue(date);
    if (props.onChange) {
      props.onChange(date, value);
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...importedProps}
        name={importedProps.name}
        value={value}
        onChange={changeValue}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        InputProps={{
          startAdornment: value && (
            <IconButton
              onClick={() => changeValue(null)}
              disabled={!value}
              style={{ order: 1 }}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
        InputAdornmentProps={{
          position: "end",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default memo(withFormsy<OriginalProps, Date>(DatePickerFormsy));
