// ProprietairesEntreprise.js
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { TextFieldFormsy } from "@fuse";
import { useTranslation } from "react-i18next";
import DatePickerFormsy from "@exiry/components/formsy/DatePickerFormsy";

function OwnerInformation() {
  const { t } = useTranslation("customer");

  return (
    <Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          className="underline"
          align="center"
          margin="20px"
        >
          PROPRIÉTAIRE(S) DE L’ENTREPRISE
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              1. {t("ownerName")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="ownerName1"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("telephone")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="ownerPhone1"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("address")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="ownerAddress1"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("birthday")}
            </Typography>
            <DatePickerFormsy
              disableToolbar
              className="w-3/5"
              variant="outlined"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="dense"
              name="ownerDateOfBirth1"
              autoOk
              id="ownerDateOfBirth1"
              required
              validationError={t("translation:error.form.required")}
              label={t("birthday")}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              2.{t("ownerName")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="ownerName2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("telephone")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="ownerPhone2"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("address")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="ownerAddress2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("birthday")}
            </Typography>
            <DatePickerFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="ownerDateOfBirth2"
              id="ownerDateOfBirth2"
              variant="outlined"
              fullWidth
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              autoOk
              label={t("birthday")}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OwnerInformation;
