import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import Formsy from "formsy-react";
import { useTranslation } from "react-i18next";
import { showMessage } from "app/store/actions";
import { useDispatch } from "react-redux";
import { handleException } from "@exiry/helpers/error.helpers";
import CompanyInformation from "../../login/tabs/CompanyInformation";
import OwnerInformation from "../../login/tabs/OwnerInformations";
import BankInformation from "../../login/tabs/BankInformation";
import CreditAgreement from "../../login/tabs/CreditAgreement";
import CreditCardAuthorization from "../../login/tabs/CreditCardAuthorization";
import PersonalBond from "../../login/tabs/PersonalBond";
import GeneralConditions from "../../login/tabs/GeneralConditions";
import { useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Loading from "@exiry/components/Loading";
import { useNavigate } from "react-router";

const gQl = gql`
  mutation CreateClientAccount($input: CreateClientAccountInput!) {
    createClientAccount(input: $input) {
      clientId
      accountNumber
      accountsPayableContact
      address
      addressIP
      authorizedSignatureDate
      bankAddress
      bankCity
      bankFax
      bankPhone
      bankPostalCode
      businessType
      cardNumber
      city
      clientAcceptanceDate
      clientName
      clientWeb
      creditCardExpirationMonth
      creditCardExpirationYear
      creditCardHolderName
      creditCardType
      email
      establishedSince
      fax
      financialInstitution
      institutionContact
      mobile
      noTransit
      ownerAddress1
      ownerAddress2
      ownerDateOfBirth1
      ownerDateOfBirth2
      ownerName1
      ownerName2
      ownerPhone1
      ownerPhone2
      personalGuaranteeDateOfBirth
      personalGuaranteeDateOfBirth2
      personalGuaranteePrintedName
      personalGuaranteePrintedName2
      personalGuaranteeSignedDay
      personalGuaranteeSignedMonth
      personalGuaranteeSignedYear
      personalGuaranteeTitle
      personalGuaranteeTitle2
      phone
      postalCode
      president
      role
      role2
      signatureDate2
      supplier1
      supplier2
      supplierAddress1
      supplierAddress2
      supplierContact1
      supplierContact2
      supplierFax1
      supplierFax2
      supplierPhone1
      supplierPhone2
      status
      accessToken
    }
  }
`;

const gql_query = gql`
  query Account($accountId: ID) {
    account(id: $accountId) {
      id
      clientName
      address
      city
      postalCode
      phone
      fax
      mobile
      email
      businessType
      establishedSince
      president
      accountsPayableContact
      ownerName1
      ownerPhone1
      ownerAddress1
      ownerDateOfBirth1
      ownerName2
      ownerPhone2
      ownerAddress2
      ownerDateOfBirth2
      financialInstitution
      noTransit
      institutionContact
      accountNumber
      bankAddress
      bankCity
      bankPostalCode
      bankPhone
      bankFax
      supplier1
      supplierAddress1
      supplierPhone1
      supplierFax1
      supplierContact1
      supplier2
      supplierAddress2
      supplierPhone2
      supplierFax2
      supplierContact2
      role
      creditCardType
      cardNumber
      creditCardExpirationMonth
      creditCardExpirationYear
      creditCardHolderName
      role2
      personalGuaranteeSignedDay
      personalGuaranteeSignedMonth
      authorizedSignatureDate
      signatureDate2
      personalGuaranteeSignedYear
      personalGuaranteeDateOfBirth
      personalGuaranteeDateOfBirth2
      personalGuaranteeTitle
      personalGuaranteeTitle2
      personalGuaranteePrintedName
      personalGuaranteePrintedName2
      clientAcceptanceDate
      requiredBond
    }
  }
`;
function JWTRegisterTab() {
  const { t } = useTranslation("customer");
  const navigate = useNavigate();
  const [mutate, { loading }] = useMutation(gQl);
  const [isFormValid, setIsFormValid] = useState(false);
  const dispatch = useDispatch();
  const { encodedToken } = useParams();
  const client = jwtDecode(encodedToken);
  const accountId = parseInt(client.clientId);
  const ref = useRef();

  const {
    data: gqlData,
    error,
    loading: clientLoading
  } = useQuery(gql_query, {
    variables: { accountId },
    fetchPolicy: "no-cache"
  });

  const accountData = gqlData?.account;

  // useEffect(() => {
  //   const autoSave = async () => {
  //     try {
  //       const input = {
  //         ...ref.current.getModel(),
  //         status: t("status.not_completed"),
  //         clientId: accountId
  //       };
  //       await mutate({
  //         variables: {
  //           input: input
  //         }
  //       });
  //     } catch (error) {
  //       handleException(error);
  //     }
  //     setIsAutoSaving(true);
  //   };

  //   let timeout;
  //   if (ref.current?.getModel()) {
  //     if (isAutoSaving && Object.keys(ref.current?.getModel()).length > 0) {
  //       timeout = setTimeout(() => {
  //         autoSave();
  //       }, 3000);
  //     }
  //   }

  //   return () => clearTimeout(timeout);
  // }, [ref, isAutoSaving, t, accountId, mutate]);

  const handleSubmit = async (model) => {
    let variant = "success";
    let message = "";
    try {
      const input = {
        ...model,
        status: t("status.completed"),
        clientId: accountId
      };
      await mutate({
        variables: {
          input: input
        }
      });
      message = t("success.created");
    } catch (error) {
      variant = "error";
      handleException(error);
      message = t("error.message");
    } finally {
      dispatch(showMessage({ message, variant }));
    }
  };

  useEffect(() => {
    if (accountData && ref.current) {
      const tmp = {};
      for (const [key, value] of Object.entries(accountData)) {
        if (value !== null) {
          tmp[key] = value;
        } else {
          tmp[key] = " ";
        }
      }
      ref.current.updateInputsWithValue(tmp);
    }
  }, [accountData]);

  if (error) {
    navigate("/add/dashboard");
  }

  if (clientLoading) {
    return <Loading />;
  }
  return (
    <div className="max-w-[100%] md:mx-auto ">
      <div className="flex gap-16 my-14 justify-center items-end">
        <div>
          <img src="/assets/images/pdf-logos/beton.png" alt="" />
          <label>Béton Chevalier inc</label>
        </div>

        <div>
          <img src="/assets/images/pdf-logos/royal.png" alt="" />
          <label>Béton Royal 2000</label>
        </div>

        <div>
          <img src="/assets/images/pdf-logos/EBC.png" alt="" />
          <label>Entreprise B. Chevalier inc.</label>
        </div>
      </div>
      <Typography variant="h6">
        <span className="underline">OUVERTURE DE COMPTE</span> COCHER ET
        PARAPHER CI-DESSUS LA (OU LES) COMPAGNIE(S) AUPRÈS DE LA (OU DESQUELLES
        ) VOUS DÉSIREZ OUVRIR UN COMPTE
      </Typography>
      <Typography className="mt-2 " variant="subtitle2" align="center">
        VEUILLEZ, S.V.P., COMPLÉTER CHACUN DES CHAMPS EN LETTRES MOULÉES. TOUTE
        DEMANDE INCOMPLÈTE OU ILLISIBLE VOUS SERA RETOURNÉE ; CE QUI
        OCCASSIONERA UN DELAI SUPPLÉMENTAITRE
      </Typography>

      <Formsy
        onValidSubmit={handleSubmit}
        onValid={() => {
          if (!isFormValid) {
            setIsFormValid(true);
          }
        }}
        onInvalid={() => {
          if (isFormValid) {
            setIsFormValid(false);
          }
        }}
        // onChange={(model) => {
        //   const input = {
        //     ...model,
        //     status: t("status.not_completed"),
        //     clientId: accountId
        //   };
        //   let variant = "success";
        //   mutate({
        //     variables: {
        //       input: input
        //     }
        //   })
        //     .catch(() => {
        //       variant = "error";
        //     })
        //     .finally(() => {
        //       // dispatch(
        //       //   showMessage({
        //       //     message: t(`${variant}.update`),
        //       //     variant
        //       //   })
        //       // );
        //       handleException(error);
        //     });
        // }}
        ref={ref}
        className="flex flex-col justify-center w-full"
      >
        <Grid>
          <CompanyInformation />
          <OwnerInformation />
          <BankInformation />
          <CreditAgreement />
          <CreditCardAuthorization />
          {accountData?.requiredBond && <PersonalBond />}
          <GeneralConditions />
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-224 mx-auto mt-16"
          id="create_account_button"
          // disabled={!isFormValid}
        >
          {t("translation:save")}
          {loading && <CircularProgress size={24} />}
        </Button>
      </Formsy>
    </div>
  );
}

export default JWTRegisterTab;
