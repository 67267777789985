// ProprietairesEntreprise.js
import React from "react";
import {
  Grid,
  Typography
  // ... autres importations nécessaires
} from "@material-ui/core";
import { TextFieldFormsy } from "@fuse";
import { useTranslation } from "react-i18next";
import DatePickerFormsy from "@exiry/components/formsy/DatePickerFormsy";

function CreditAgreement() {
  const { t } = useTranslation("customer");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          className="underline"
          margin="20px"
          align="center"
        >
          {t("credit_agreement")}
        </Typography>
        <Typography variant="subtitle1">
          Net 30 jours. Après 30 jours, nous nous engageons à payer un intérêt
          composé de 2% par mois, représentant un taux de 24% l’an sur tous
          soldes dus et impayés.
        </Typography>
        <Typography variant="subtitle1">
          Advenant tout défaut d’effectuer le paiement dans les délais prévus,
          nous acceptons de payer à la (ou aux) compagnie(s) ci-avant
          sélectionnée(s) des dommages-intérêts additionnels équivalant à vingt
          pour cent (20%) du solde dû en capital et intérêts afin de compenser
          les frais de recouvrement encourus aux fins d’obtenir parfait
          paiement, notamment, les déboursées et honoraires d’avocats.
        </Typography>
        <Typography variant="subtitle1">
          Il est entendu que toute marchandise vendue et/ou livrée restera la
          propriété de la (ou les) compagnie(s) ci-avant sélectionnée(s) jusqu’à
          parfait paiement.
        </Typography>
        <Typography variant="subtitle1">
          Nous convenons que la présente convention de crédit sera interprétée
          selon les lois de la province de Québec et élisons domicile dans le
          district judiciaire de Québec, province de Québec, Canada.
        </Typography>
        <Typography variant="subtitle1">
          Nous autorisons la (ou les) compagnie(s) ci-avant sélectionnée(s) à
          recevoir, à communiquer et à consulter les renseignements sur le
          compte et l’expérience de crédit de l’entreprise auprès de toutes
          institutions financières, prêteurs, bureaux de crédit et de tous
          autres fournisseurs et enjoignons ces derniers de fournir, sur
          demande, tous les renseignements financiers actuels de l’entreprise à
          la (ou les) compagnie(s) ci-avant sélectionnée(s) dans les meilleurs
          délais.
        </Typography>
      </Grid>
      <Grid item xs={12} className="flex items-center">
        <Typography className="w-2/5" variant="subtitle1">
          {t("authorizedSignature")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("role")}
            </Typography>
            <TextFieldFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="role"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="flex items-center">
            <Typography className="w-2/5" variant="subtitle1">
              {t("date")}
            </Typography>
            <DatePickerFormsy
              required
              validationError={t("translation:error.form.required")}
              margin="dense"
              className="w-3/5"
              name="authorizedSignatureDate"
              id="authorizedSignatureDate"
              variant="outlined"
              fullWidth
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              autoOk
              label={t("date")}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CreditAgreement;
