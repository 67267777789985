import { Navigate } from "react-router-dom";
import AuthConfigs from "app/main/auth";
import UsersConfigs from "../main/users";
import CustomerConfigs from "../main/customer";
import { PortalConfigs } from "../main/portal/portalConfigs";
import { generateRoutesFromConfigs } from "@fuse/utils/routes";

const routeConfigs = [
  ...AuthConfigs,
  ...UsersConfigs,
  ...PortalConfigs,
  ...CustomerConfigs
];

//defaultAuth: ['admin']

const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    path: "*",
    element: <Navigate to="/app/dashboard" />
  }
];

export default routes;
