import jwtDecode, { JwtPayload } from "jwt-decode";
import FuseUtils from "@fuse/FuseUtils";
import { handleException } from "@exiry/helpers/error.helpers";

const EventEmitter = FuseUtils.EventEmitter;

class jwtService extends EventEmitter {
  init() {
    this.handleAuthentication();
  }

  handleAuthentication = () => {
    // Get Token
    let access_token = this.getAccessToken();

    // If there is no access token emit()
    if (!access_token) {
      this.emit("onNoAccessToken");
      return;
    }

    // Verify the token
    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "error.session_expired");
    }
  };

  handleLogin = (token) => {
    this.setSession(token);
  };

  logout = () => this.setSession(null);

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
    } else {
      localStorage.removeItem("jwt_access_token");
    }
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }

    try {
      const decoded = jwtDecode(access_token) as JwtPayload;
      const currentTime = Date.now() / 1000;
      if (decoded.exp >= currentTime) {
        return true;
      }
    } catch (error) {
      handleException(error);
    }

    return false;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  getAuthHeaders = () => {
    const token = this.getAccessToken();
    const header = {};
    if (token) {
      // @ts-ignore
      header.authorization = "Bearer " + this.getAccessToken();
    }
    return header;
  };
}

const instance = new jwtService();

export default instance;
