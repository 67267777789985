import { fuseDark } from "@fuse/fuse-colors/";
import colors from "tailwindcss/colors";

const themesConfig = {
  default: {
    palette: {
      type: "light",
      primary: fuseDark,
      secondary: {
        light: "#1E67CC",
        main: "#1852A4",
        dark: "#134181",
      },
      error: colors.red,
    },
    status: {
      danger: "orange",
    },
  },
};

export default themesConfig;
