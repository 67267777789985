const initialState = {};

const forgetpassword = function (state = initialState, action) {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export default forgetpassword;
