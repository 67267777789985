import React from "react";
import {
  Grid,
  Typography
  // ... autres importations nécessaires
} from "@material-ui/core";
import { TextFieldFormsy } from "@fuse";
import { useTranslation } from "react-i18next";

function CompanyInformation() {
  const { t } = useTranslation("customer");

  return (
    <div>
      <Typography
        variant="h6"
        className="underline"
        margin="30px"
        align="center"
      >
        RENSEIGNEMENT SUR L’ENTREPRISE
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} className="flex items-center">
          <Typography className="w-1/5" variant="subtitle1">
            {t("clientName")}
          </Typography>
          <TextFieldFormsy
            margin="dense"
            className="w-4/5"
            name="clientName"
            variant="outlined"
            fullWidth
            required
            validationError={t("translation:error.form.required")}
          />
        </Grid>
        <Grid item xs={12} className="flex items-center">
          <Typography className="w-1/5" variant="subtitle1">
            {t("address")}
          </Typography>
          <TextFieldFormsy
            margin="dense"
            className="w-4/5"
            name="address"
            variant="outlined"
            fullWidth
            required
            validationError={t("translation:error.form.required")}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("city")}
              </Typography>
              <TextFieldFormsy
                margin="dense"
                className="w-3/5"
                name="city"
                variant="outlined"
                fullWidth
                required
                validationError={t("translation:error.form.required")}
              />
            </Grid>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("postalCode")}
              </Typography>
              <TextFieldFormsy
                margin="dense"
                className="w-3/5"
                name="postalCode"
                variant="outlined"
                fullWidth
                required
                validationError={t("translation:error.form.required")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("telephone")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-3/5"
                name="phone"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("Fax")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-3/5"
                name="fax"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("cell")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-3/5"
                name="mobile"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className="flex items-center">
              <Typography className="w-2/5" variant="subtitle1">
                {t("email")}
              </Typography>
              <TextFieldFormsy
                required
                margin="dense"
                className="w-3/5"
                name="email"
                variant="outlined"
                fullWidth
                placeholder="test@test.com"
                validations={{
                  matchRegexp:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                }}
                validationError={t("translation:error.form.required")}
                validationErrors={{
                  matchRegex: t("translation:error.form.email")
                }}
              />
            </Grid>
            <Grid item xs={12} className="flex items-center">
              <Typography className="w-1/5" variant="subtitle1">
                {t("businessType")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-4/5"
                name="businessType"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex items-center">
              <Typography className="w-1/5" variant="subtitle1">
                {t("establishedSince")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-4/5"
                name="establishedSince"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex items-center">
              <Typography className="w-1/5" variant="subtitle1">
                {t("president")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-4/5"
                name="president"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex items-center">
              <Typography className="w-1/5" variant="subtitle1">
                {t("accountsPayableContact")}
              </Typography>
              <TextFieldFormsy
                required
                validationError={t("translation:error.form.required")}
                margin="dense"
                className="w-4/5"
                name="accountsPayableContact"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default CompanyInformation;
