import LoginConfig from "app/main/auth/login";
import RegisterConfig from "app/main/auth/register/";
import DenounciationConfig from "app/main/auth/denounciation";
import ForgotPasswordPageConfig from "app/main/auth/forgot-password";
import ResetPasswordPageConfig from "app/main/auth/reset-password";

const AuthConfigs = [
  LoginConfig,
  RegisterConfig,
  ForgotPasswordPageConfig,
  DenounciationConfig,
  ResetPasswordPageConfig
];

export default AuthConfigs;
