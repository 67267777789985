import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { withFormsy } from "formsy-react";
import { PassDownProps } from "formsy-react/dist/withFormsy";
import _ from "@lodash";

type Props = TextFieldProps & PassDownProps<string>;

function TextFieldFormsy(props: Props, ref) {
  const importedProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "children",
    "className",
    "classes",
    "defaultValue",
    "disabled",
    "FormHelperTextProps",
    "fullWidth",
    "id",
    "InputLabelProps",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "multiline",
    "name",
    "onBlur",
    "onChange",
    "onFocus",
    "placeholder",
    "required",
    "rows",
    "minRows",
    "rowsMax",
    "select",
    "SelectProps",
    "type",
    "variant",
    "margin",
  ]) as TextFieldProps;

  const errorMessage = props.errorMessage;
  const value = props.value || "";

  function changeValue(event) {
    let value = event.currentTarget.value;
    props.setValue(value);
    if (props.onChange) {
      props.onChange(event);
    }
  }

  return (
    <TextField
      {...importedProps}
      onChange={changeValue}
      value={value}
      inputRef={ref}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
    />
  );
}

const TextFieldFormsyRef = React.forwardRef<unknown, Props>(TextFieldFormsy);
TextFieldFormsyRef.displayName = "TextFieldFormsy";

export default React.memo(
  // @ts-ignore
  withFormsy<TextFieldProps, string>(TextFieldFormsyRef)
);
